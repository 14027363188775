<template>
  <div>
    <h1>{{ $t('views.faq.h1_title') }}</h1>
    <div v-for="(chapter, i) in faq" :key="`chapter${i}`">
      <h2>{{ chapter.title }}</h2>
      <v-expansion-panels :active-class="backgroundColor" flat accordion>
        <v-expansion-panel v-for="(question, j) in chapter.questions" :key="`question${j}`" class="transparent">
          <v-expansion-panel-header class="px-4">
            <h3>{{ question.title }}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mx-n2">
            <p v-for="(paragraph, k) in question.paragraphs" :key="`paragraph${k}`">
              {{ paragraph }}
            </p>
            <div v-if="typeof question.youtube !== 'undefined'">
              <youtube :video-id="question.youtube" :player-width="player.width" :player-height="player.height"></youtube>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import BtnHome from '@/components/btn/Home'
import { theme } from '@/mixins'
import { responsive } from '@/mixins'

export default {
  name: 'Faq',
  components: {
    Adslot,
    BtnHome,
  },
  mixins: [responsive, theme],
  data() {
    return {
      faq: [
        {
          title: this.$t('faq.chapter_1.h2_title'),
          questions: [
            {
              title: this.$t('faq.chapter_1.question_1.h3_title'),
              paragraphs: [this.$t('faq.chapter_1.question_1.p_text_1')],
            },
            {
              title: this.$t('faq.chapter_1.question_2.h3_title'),
              paragraphs: [this.$t('faq.chapter_1.question_2.p_text_1'), this.$t('faq.chapter_1.question_2.p_text_2')],
            },
            {
              title: this.$t('faq.chapter_1.question_3.h3_title'),
              paragraphs: [this.$t('faq.chapter_1.question_3.p_text_1'), this.$t('faq.chapter_1.question_3.p_text_2')],
              youtube: 'F-5KtlWuZ2U',
            },
          ],
        },
        {
          title: this.$t('faq.chapter_2.h2_title'),
          questions: [
            {
              title: this.$t('faq.chapter_2.question_1.h3_title'),
              paragraphs: [this.$t('faq.chapter_2.question_1.p_text_1')],
            },
          ],
        },
      ],
    }
  },
  computed: {
    backgroundColor() {
      return this.$theme.views.faq.questions.backgroundColor
    },
    player() {
      if (this.breakpoint === 'xs')
        return {
          width: 266,
          height: 150,
        }
      if (this.breakpoint === 'sm')
        return {
          width: '520',
          height: '293',
        }
      if (this.breakpoint === 'md')
        return {
          width: '432',
          height: '243',
        }
      return {
        width: 694,
        height: 390,
      }
    },
  },
}
</script>

<style scoped></style>
